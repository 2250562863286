<script>
// Cool way to render Vue components from HTML Strings
// https://medium.com/haiiro-io/compile-markdown-as-vue-template-on-nuxt-js-1c606c15731c
import VueWithCompiler from "vue/dist/vue.esm";
import { VTooltip } from "vuetify/lib";

export default {
  //I expect just a string with <v-tooltip> component
  //If I recive html tag like "<p>Workers are....<v-tooltip>....</p>" it'll not work properly
  //"Workers are....<v-tooltip>...." this is ok.
  props: ["codeProps"],
  data() {
    return {
      templateRender: undefined
    };
  },
  components: {
    VTooltip
  },
  created() {
    this.updateRender();
  },
  computed: {
    code() {
      return this.codeProps.replace(/{{|}}/g, "");
    }
  },
  watch: {
    code: function() {
      this.updateRender();
    }
  },
  methods: {
    updateRender() {
      const compiled = VueWithCompiler.compile("<div>" + this.code + "</div>");
      this.templateRender = compiled.render;
      this.$options.staticRenderFns = [];
      for (const staticRenderFunction of compiled.staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction);
      }
    }
  },
  render() {
    return this.templateRender();
  }
};
</script>

<style scoped>
h3 span {
  padding-bottom: 1px;
  display: inline-block;
  border-bottom: 2px solid #000000;
}
</style>
